import React from "react";
import "./Common.css";

export const CustomHeading = (props) => {
  return (
    <h3 className="custom-heading text-capitalize" {...props}>
      {props.Heading}
    </h3>
  );
};

export const CustomSubTitle = (props) => {
  return <h6 className="custom-subtitle">{props.SubTitle}</h6>;
};

export const CustomDescription = (props) => {
  return (
    <p className="custom-description" {...props}>
      <span>{props.startIcon}</span> {props.Description}
    </p>
  );
};

export const ApiCusomDescription = (props) => {
  return (
    // <h3 className="apidetaildescription" {...props}>
    //    {props.ApiDetailDescription1 } <span className="apidetailhighlightdescription">{props.ApihighlightDescription }</span> {props.ApiDetailDescription2 }
    // </h3>
    <h4 className="apidetaildescription" style={props.styleProps}>
      {props.ApiDetailDescription1}
      <span className="apidetailhighlightdescription" style={props.styleProps}>
        {props.ApihighlightDescription}
      </span>
      {props.ApiDetailDescription2}
    </h4>
  );
};

export const CusomHighLightDescription = (props) => {
  return (
    // <h3 className="apidetaildescription" {...props}>
    //    {props.ApiDetailDescription1 } <span className="apidetailhighlightdescription">{props.ApihighlightDescription }</span> {props.ApiDetailDescription2 }
    // </h3>
    <h4 className="apidetaildescription" style={props.styleProps}>
      {props.ApiDetailDescription1}
      <span className="apidetailhighlightdescription" style={props.styleProps}>
        {props.ApihighlightDescription}
      </span>
      {props.ApiDetailDescription2}
    </h4>
  );
};