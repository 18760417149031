// Author: Shubham Sonkar

// Import necessary dependencies and styles
import React from "react";
import { QueryClientProvider, QueryClient } from "react-query";
import "bootstrap/dist/css/bootstrap.min.css";
import Mui from "./context/Mui";
import Bootstrap from "./context/Bootstrap";
import MyGlobalData from "./context/Global";
import Main from "./setting/Main";
import "./App.css";

// Create a new instance of QueryClient
export const queryClient = new QueryClient();

// Author: Shubham Sonkar
// Define the main App component
const App = () => {
  // Local state to manage loading and notification
  const [loading, setLoading] = React.useState(true);

  // Get the spinner element by ID
  const spinner = document.getElementById("spinner");

  // Hide the spinner after a delay and update the loading state
  if (spinner) {
    setTimeout(() => {
      spinner.style.display = "none";
      setLoading(false);
    }, 200);
  }

  // Render the application once loading is complete
  return (
    !loading && (
      <QueryClientProvider client={queryClient}>
        {/* Wrapping components with Mui, Bootstrap, and MyGlobalData context providers */}
        <Mui>
          <Bootstrap>
            <MyGlobalData>
              {/* Main component representing the main content of the application */}
              <Main />
            </MyGlobalData>
          </Bootstrap>
        </Mui>
      </QueryClientProvider>
    )
  );
};

// Export the App component as the default export
export default App;
